import React from "react";
import { Modal, Menu, Icon } from "semantic-ui-react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import ProjectEditView from "../projecteditview";
import omit from "lodash.omit";
import moment from "moment";

class JobEditModal extends React.Component {
	constructor(props) {
		super(props);
		this.close = this.close.bind(this);
		this.save = this.save.bind(this);
		this.isFullPlanning = false;
		this.state = {
			pid: "",
			forceRemerge: 1,
			data: {},
			neverChanged: true,
			deleteMode: false,
			usedList: new Set(),
			marked: new Set(),
			orders: new Map(),
			suppliers: new Set(),
			requests: new Map(),
			disposition: new Map(),
			activeIndex: 0,
			title: "",
			companyName: props.app.basedata.company?.name ?? ""
		};
	}

	handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

	checkForChanges() {
		return this.state.neverChanged;
	}

	saveHistory(){
		//does nothing in the job editor (overwrite)
	}

	static getDerivedStateFromProps(props, state) {
		if (
			state.pid !==
			(props.app.ui.jobEditModal !== false
				? props.app.ui.jobEditModal.project.id
				: "")
		) {
			return JobEditModal.getProjectData(
				props.app.ui.jobEditModal !== false
					? props.app.ui.jobEditModal.project.id
					: false,
				props
			);
		}
		return null;
	}

	componentDidUpdate(prevProps, prevState){
		if (this.props.app.ui.jobEditModal !== false && this.state.pid === "" && prevState.pid !== this.state.pid){
			this.close();
		}
	}

	static getProjectData(pid, props) {
		

		if (
			!props.app.projects.projects.has(pid) ||
			props.app.projects.projects.get(pid).deleted
		)
			return {
				pid: "",
				forceRemerge: 1,
				data: {"xx":"first"},
				neverChanged: true,
				deleteMode: false,
				marked: new Set(),
				suppliers: new Set(),
				orders: new Map(),
				disposition: new Map(),
				requests: new Map(),
				agfAcceptedMaterials: [],
				title: ""
			};

			const marked =
			props.app.ui.jobEditModal.process.id +
			"#" +
			props.app.ui.jobEditModal.id;

		//console.log(marked);

		let projectData;
		let orderData = new Map();
		let requestData = new Map();
				let agfAcceptedMaterials = new Set();
		let dispoData = new Map();
		
			const pNode = props.app.projects.projects.get(pid);
			orderData = pNode.jobMapList("$orders");
			dispoData = pNode.jobMapList("$disposition");
			requestData = pNode.jobMapList("$requests");
			projectData = pNode.oldFormat();
			if (pNode.agfData  && pNode.agfData.data && pNode.agfData.hash === pNode.agfData.hashAccepted && pNode.agfData.data.sections.length){
				for (let section of pNode.agfData.data.sections){
					if (!section.layers || section.layers.length === 0)
						continue;
					for (let layer of section.layers){
						agfAcceptedMaterials.add([layer.mixtureName,layer.mixingPlant,layer.mixtureCode].join("#####"));
					}
				}
			}

		const suppliers = new Set();
		
		for (let o of orderData.values()){
			if (!o.supplier) continue;
			suppliers.add(o.supplier);
		}

		for (let o of dispoData.values()){
			if (!o.supplier) continue;
			suppliers.add(o.supplier);
		}

		const currentJobId = props.app.ui.jobEditModal.id;
		const usedList = new Set();
		for (let job of Object.values(projectData.processes[props.app.ui.jobEditModal.process.id].jobs)){
			if (job.id === currentJobId)
				continue;
			usedList.add(moment(job.start).format("YYYY-MM-DD"));
		}
			
		return {
			pid: pid,
			data: projectData,
			orders: orderData,
			usedList,
			suppliers, //TODO SUPPLIERS
			agfAcceptedMaterials: Array.from(agfAcceptedMaterials).map(x => x.split("#####")).sort((a,b) => a[0].localeCompare(b[0])),
			disposition: dispoData,
			requests: requestData,
			marked: new Set([marked]),
			title: [pNode.fullName,props.app.ui.jobEditModal.process.name,moment(props.app.ui.jobEditModal.start).format("DD.MM.YYYY")].join(" - ")
		};
	}

	addSupplier(id){
		if (!id) return false;
		this.setState(x => {
			let fx = new Set(x.suppliers);
			fx.add(id); 
			return {suppliers: fx};
		});
	}

	resolveOrderDisposition(snapshot) {
		return snapshot;
	}

	isDayFree(mm){
		const key = mm.format("YYYY-MM-DD");
		return this.state.usedList.has(key);
	}

	retrieveJobData(c) {
		const process = this.props.app.ui.jobEditModal.process.id;
		if (!(process in this.state.data.processes)) return [];
		const job = this.props.app.ui.jobEditModal.id;

		const jobdata = this.state.data.processes[process].jobs[job];
		return [
			{
				id: process + "#" + job,
				data: {
					...omit(jobdata, ["id", "deleted"]),
					start: moment(jobdata.start).format("HH:mm"),
					end: moment(jobdata.end).format("HH:mm"),
					date: moment(jobdata.start).format("YYYY-MM-DD"),
					paving_start: moment(jobdata.paving_start).format("HH:mm"),
				}
			}
		];
	}
	changeProperty(sub, changes) {
		if (sub === "main") {
			this.setState(state => ({
				neverChanged: false,
				data: {
					...state.data,
					updatedAt: new Date(),
					...changes
				}
			}));
		} else {
			this.setState(state => ({
				neverChanged: false,
				data: {
					...state.data,
					processes: {
						...state.data.processes,
						[sub]: {
							...state.data.processes[sub],
							updatedAt: new Date(),
							...changes
						}
					}
				}
			}));
		}
	}
	changeJobProperty(changeFunction) {
		this.setState(state => ({
			neverChanged: false,
			...changeFunction(state)
		}));
	}

	save() {
		this.props.app.projects.saveProjectEdit(this.state.data, this.state.orders, this.state.disposition, this.state.requests);
		this.close();
	}

	close() {
		this.props.app.ui.closeJobEditModal();
	}

	render() {
		//console.log(this.state.data);
		if (!this.props.app.ui.jobEditModal) return null;
		return (<React.Fragment>
			<Modal
				centered={false}
				className="jobEditModal"
				open={this.props.app.ui.jobEditModal !== false}
				onClose={this.close}
			>
				<Menu>
					<Menu.Item header>
						{this.props.t("jobEdit.heading")}
					</Menu.Item>
					<Menu.Menu position="right">
						<Menu.Item name="close it" onClick={this.close}>
							<Icon name="close" /> {this.state.data.editable ? this.props.t("cancel") : ""}
						</Menu.Item>
						{this.state.data.editable ? <Menu.Item name="save it" onClick={this.save}>
							<Icon name="check" /> {this.props.t("ok")}
						</Menu.Item> : null}
					</Menu.Menu>
				</Menu>
				<div className="outerProjectName">{this.state.title}</div>
				<Modal.Content>
					<div className="jobEditWrapper">
						<ProjectEditView
							global={false}
							secondary={true}
							edit={this}
							marked={this.state.marked}
							forceRemerge={this.state.forceRemerge}
							companyName={this.state.companyName}
						/>
					</div>
				</Modal.Content>
			</Modal>
			
				</React.Fragment>
		);
	}
}

JobEditModal.displayName = "JobEditModal";

export default withTranslation()(inject("app")(observer(JobEditModal)));
